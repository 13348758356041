<template>
  <div class="mx-4 elevation-10 rounded-lg pa-4" >
    <v-row>
      <v-col cols="12" sm="4" md="4">
          <div class="subtitle-1 font-weight-black green--text text--darken-2 ml-2">SUMMARY PRODUCT</div>
      </v-col>
      <v-col cols="12" md="8" sm="8">
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="4" md="4">
        <div class="ml-2 mt-4 mb-2">
          <v-date-picker
              v-model="datePicker1"
              type="date"
              @change="datePicker1Change($event, toggleValue)"
              color="green"
              elevation="10"
              class="rounded-lg"
          ></v-date-picker>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <v-row no-gutters class="mb-2">
          <v-col cols="12" sm="6" md="6">
            <div>
              <v-btn-toggle
                  v-model="toggleValue"
                  dense
                  color="green"
              >
                <v-btn value="inQty"  @click="toggleClick('inQty')">
                  <span class="caption font-weight-bold">Quantity</span>
                </v-btn>

                <v-btn value="inValue"  @click="toggleClick('inValue')">
                  <span class="caption font-weight-bold">Value</span>
                </v-btn>

              </v-btn-toggle>
            </div>

          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="itemsQtySales"
            :search="search"
            :page.sync="currentPage"
            :items-per-page.sync="pageSize"
            dense
        >
          <template v-slot:[`item.number`]="{ index }">
            {{ (index + ((currentPage-1)*pageSize) +1 )}}
          </template>

          <template v-slot:[`item.description`]="{ item }">
            {{item.description}} <span class="grey--text ml-1 caption">{{item.shortDesc}}</span>
          </template>

          <template v-slot:[`item.sumResultPagi`]="{ item }">
            <div class="green--text text--darken-3 text-end">
              {{item.sumResultPagi}}
            </div>
          </template>

          <template v-slot:[`item.sumResultSiang`]="{ item }">
            <div class="blue--text  text-end">
                {{item.sumResultSiang}}
            </div>
          </template>

          <template v-slot:[`item.sumResult`]="{ item }">
            <div class="text-end mr-10 ">
              <span class="grey--text mr-2 caption font-weight-light">{{item.countResult}} rows</span>
              <span>{{ formattedCurrencyValue(item.sumResult) }}</span>
            </div>
          </template>

        </v-data-table>

      </v-col>
    </v-row>

  </div>
</template>

<script>

import FtSaleshFilter from "@/models/payload/ft-salesh-filter";
import KonversiProductHelper from "@/services/konversi-product-helper";
import FtSaleshService from "@/services/apiservices/ft-salesh-service";

export default {
  name: "SalesSummaryProduct",
  props:{
    // ftSaleshFilterDate: Object
  },
  data (){
    return {
      ftSaleshFilterDate: new FtSaleshFilter(),
      itemsQtySales:[],

      tab: 'tab1',
      dialogShow: false,

      toggleValue: 'inQty',

      currentPage: 1,
      pageSize: 50,
      pageSizes: [10, 25, 50, 150, 500],


      datePicker1: new Date().toISOString().substr(0, 10),
      search : '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Nama Product', value: 'description', width:"30%", sortable: true },
        { text: 'Kirim PAGI', value: 'sumResultPagi', sortable: true },
        { text: 'Kirim Siang', value: 'sumResultSiang', sortable: true },

        { text: 'Total', value: 'sumResult', sortable: true },
      ],

      itemSelected: '',

    }
  },
  computed:{

    itemsFMaterial(){
      return this.$store.state.data.listFMaterial
    },

  },
  methods:{
    lookupUom1234StringUom(fmaterial, pcsOrUom4){
      return KonversiProductHelper.getUom1234StringUom(fmaterial, pcsOrUom4)
    },

    dialogClose(){
      this.dialogShow = false
    },

    datePicker1Change(value, toggle){
      if (toggle === 'inValue'){
        this.eventFromDashboardProductTerlarisValue(value)
      }else {
        this.eventFromDashboardProductTerlarisQty(value)
      }
    },

    eventFromDashboardProductTerlarisQty(value){
      value.toString();
      // let startDate = value
      // let endDate = value

      let orderDateFrom = new Date(value)
      let orderDateTo = new Date(value)


      let invoiceDateFrom = new Date(2000, 1, 1)
      let invoiceDateTo = new Date(2999, 1, 1)


      let ftSaleshFilter = new FtSaleshFilter()
      ftSaleshFilter.orderDateFrom =  orderDateFrom
      ftSaleshFilter.orderDateTo =  orderDateTo
      ftSaleshFilter.invoiceDateFrom =  invoiceDateFrom
      ftSaleshFilter.invoiceDateTo =  invoiceDateTo

      ftSaleshFilter.pageNo = this.currentPage
      ftSaleshFilter.pageSize = this.pageSize
      ftSaleshFilter.sortBy = "id"
      ftSaleshFilter.order = "DESC"
      ftSaleshFilter.search = this.search

      this.itemsQtySales = []
      FtSaleshService.getAllFtSaleshContainingDesSimple(ftSaleshFilter).then(
          response =>{
            const { items, totalPages} = response.data
            this.ftSaleshs = items
            this.totalPaginationPages = totalPages

            console.log(this.ftSaleshs.length)


            let listFtSalesdItems = []
            items.forEach(ftSalesh => {
              ftSalesh.listFtSalesdItems.forEach(ftSalesditems =>{
                let newFtSalesditems = ftSalesditems

                newFtSalesditems.priority = ftSalesh.priority
                listFtSalesdItems.push(newFtSalesditems)
              })
            })

             this.itemsFMaterial.forEach(fmaterialBean =>{

               let sumResult = 0;
               let sumResultPagi = 0;
               let sumResultSiang = 0;

               let countResult = 0;
               listFtSalesdItems.filter(x => x.fmaterialBean === fmaterialBean.id).forEach(ftSalesdItems => {
                 sumResult += ftSalesdItems.qty
                 if (ftSalesdItems.priority === 0) {
                   sumResultPagi += ftSalesdItems.qty
                 }else {
                   sumResultSiang += ftSalesdItems.qty
                 }

                 countResult +=1
               })

               if (countResult >0){
                 this.itemsQtySales.push({description: fmaterialBean.pname,
                   sumResult: sumResult, sumResultPagi: sumResultPagi, sumResultSiang: sumResultSiang,
                   countResult: countResult, id: fmaterialBean.id});
               }

             })

          },
          error =>{
            console.log(error)
          }
      )


    },
    eventFromDashboardProductTerlarisValue(value){

      console.log(value)

    },

    toggleClick(toggleValue){
      this.datePicker1Change(this.datePicker1, toggleValue)
    },

    formattedCurrencyValue(value){
      if (!value){return 0 }
      return parseFloat(Math.round(value)).toLocaleString()
    },
    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    }

  },
  mounted() {
    this.$store.dispatch('data/loadFMaterial')
    this.datePicker1Change(this.datePicker1, this.toggleValue)
  }
}
</script>

<style scoped>

</style>