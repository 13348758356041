<template>
  <v-card class="mt-2 ml-2 mr-2">
    <SalesSummaryProduct/>
  </v-card>
</template>

<script>

import SalesSummaryProduct from "@/components/sales/summary/SalesSummaryProduct.vue";
export default {
  name: "SalesSummaryProductView",
  components: { SalesSummaryProduct },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>